import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-_6c15f6f5eff32ca1fefde6f1dd3b49e2/node_modules/next/dist/client/components/render-from-template-context.js");
